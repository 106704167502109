.testForm {
  text-align: center;
  position: relative;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: rgb(235 248 255);
  display: flex;
  flex-direction: column;
  &__button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px;
    border-radius: 6px;
    border: 1px solid #4299e1 !important;
    background: #4299e1 !important;
    color: var(--White, #fff);
    text-align: center;
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    transition: 0.3s all;
    &:hover {
      transition: 0.3s all;
      border: 1px solid #329df3 !important;
      background: #329df3 !important;
      color: var(--White, #fff) !important;
    }
    &__white {
      border: 1px solid #fff !important;
      background: #fff !important;
      color: #329df3;
      &:hover {
        transition: 0.3s all;
        border: 1px solid #fff !important;
        background: #fff !important;
        color: #329df3 !important;
      }
    }
    &__finish {
      color: #f56565;
      border: 1px solid #f56565 !important;
      background-color: #fff !important;

      &:hover {
        color: #fff;
        border: 1px solid #fff !important;
        background-color: #f56565 !important;
      }
    }
  }
}

.title {
  margin-bottom: 8px;
  color: blue;
  text-align: center;
  font-family: Gilroy;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
}

.timer {
  display: flex;
  align-items: center;
  color: #000 !important;
  text-align: center;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  transition: 0.3s all;
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 20px;
}

.questionTabs {
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 12px;
  background-color: #fff;
}

.questionTab {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #329df3;
  background: #329df3;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  color: black;
  text-align: center;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  &:hover,
  &__isActive {
    border: 2px solid black;
    text-align: center;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    transform: scale(1.15);
  }
}

.questionText {
  padding: 0 40px;
  margin-top: 12px;
  margin-bottom: 32px;
  text-align: center;
  color: #000;
  font-family: Gilroy;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.options {
  padding: 0 40px;
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.option {
  margin-bottom: 36px;
  color: #000;
  text-align: left;
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.navigationButtons {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  background-color: #329df3;

  &__white {
    justify-content: space-between;
    background-color: #fff;
  }
}

.unanswered {
  border-color: red;
}

.answered {
  border-color: #4db674;
  background-color: #4db674;
  color: #000;
}

.currentQuestionNumber {
  text-align: center;
  color: #000;
  font-family: Gilroy;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.divider {
  height: 1px;
  width: 100%;
  background: #000;
}

.currentTestTitle {
  font-weight: 800;
  font-size: 26px;
  margin-bottom: 0;
}

.questionImage {
  display: flex; /* Enables flexbox for centering */
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically if needed */
  margin: 16px 0;

  .image {
    max-width: 50%; /* Adjust as needed for your design */
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
}
